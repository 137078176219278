import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/vercel/path0/apps/blog/src/components/PageLayout/PageLayout.tsx";
import { Container, Email, PageOffset } from 'components';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <p>{`I do not explicitly collect any sort of personal data on my website. The contact form contains a field to let me know how to contact the sender back.
However, this field is not obligatory and it is totally up to the author what kind of information to provide there.`}</p>
    <p>{`Any kind of personal data the author has voluntarily submitted while filling up the body of the message, as well as in the contact field, I do not store and do not process.
After I receive and read the message, it gets immediately wiped out of the system.`}</p>
    <p>{`However, if you are still looking for more information you can contact me by sending an email to `}<Email mdxType="Email" /></p>
    <PageOffset mdxType="PageOffset" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      